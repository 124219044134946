import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Container, Row, Col } from "react-bootstrap"
import { RiArrowRightSLine } from "react-icons/ri"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { useIntl, Link } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ThePage = () => {
  /*
  const data = useStaticQuery(graphql`
    query abtQuery {
      img1: file(relativePath: { eq: "sensory01.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 695) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img2: file(relativePath: { eq: "sensory02.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 695) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img3: file(relativePath: { eq: "sensory03.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 695) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img4: file(relativePath: { eq: "sensory04.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 695) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)
  */

  const intl = useIntl()
  const tt = key => {
    if (intl.messages[key] === undefined) {
      return key
    } else if (intl.messages[key] === "") {
      return ""
    } else {
      return intl.formatMessage({ id: key })
    }
  }

  return (
    <Layout>
      <SEO title="seo.title.jha" description="seo.desc.main" />
      <div className="content-master">
        <Container>
          <Row>
            <Col xs={12}>
              <h3>
                <div
                  css={css`
                    text-align: center;
                    font-weight: bold;
                  `}
                >
                  {tt("pages.jha.title1")}
                </div>
              </h3>
            </Col>
          </Row>
          <Row className="pt-4">
            <Col className="pb-3">
              <div className="text-dark">
                <div>{tt("pages.jha.desc1")}</div>
              </div>
            </Col>
          </Row>
          <Row className="pt-4">
            <Col className="pb-3">
              <div className="d-flex flex-column">
                <h5 className="font-weight-bold">{tt("pages.jha.title2")}</h5>
                <div className="text-dark">{tt("pages.jha.desc2")}</div>
              </div>
            </Col>
          </Row>
          <Row className="pt-4">
            <Col className="pb-3">
              <div className="d-flex flex-column">
                <h5 className="font-weight-bold">{tt("pages.jha.title3")}</h5>
                <div className="text-dark">{tt("pages.jha.desc3")}</div>
              </div>
            </Col>
          </Row>
          <Row className="pt-4">
            <Col className="pb-3">
              <div className="d-flex flex-column">
                <h5 className="font-weight-bold">{tt("pages.jha.title4")}</h5>
                <div className="text-dark">{tt("pages.jha.desc4")}</div>
              </div>
            </Col>
          </Row>
          <Row className="pt-4">
            <Col className="pb-3">
              <div className="d-flex flex-column">
                <h5 className="font-weight-bold">{tt("pages.jha.title5")}</h5>
                <div className="text-dark">{tt("pages.jha.desc5")}</div>
              </div>
            </Col>
          </Row>
          <Row className="pt-4">
            <Col className="pb-3">
              <div className="d-flex flex-column">
                <h5 className="font-weight-bold">{tt("pages.jha.title6")}</h5>
                <div className="text-dark">{tt("pages.jha.desc6")}</div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default ThePage
